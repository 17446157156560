import eachElement from 'Utils/eachElement.js';
import Parallax from 'parallax-js';

document.addEventListener('DOMContentLoaded', () => {
	if (window.matchMedia('(prefers-reduced-motion: no-preference)').matches) {
		eachElement('.parallax', element => {
			new Parallax(element);
		});
	}
});
